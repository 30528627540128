<template>
  <form-field
    :label="label"
    :name="name"
    :focussed="focussed"
    :loading="loading"
    :disabled="disabled"
    :valid="valid"
    :invalid="invalid"
    :errors="errors"
  >
    <label
      class="toggle"
      :class="classes"
      :disabled="disabled"
    >
      <div class="switch">
        <base-icon
          v-if="!loading"
          class="icon icon--enabled"
          name="checkmark-outline"
          :size="16"
        />
        <base-icon
          v-if="!loading"
          class="icon icon--disabled"
          name="close-outline"
          :size="16"
        />
        <base-icon
          v-if="loading"
          class="icon icon--loading"
          name="loader-outline"
          :size="16"
          animation="spin"
        />
        <span class="knob" />
        <input
          class="checkbox"
          type="checkbox"
          :checked="value"
          :disabled="disabled"
          @change="$emit('input', !value)"
        >
      </div>

      <span
        v-if="hasLabel"
        class="switch-label"
      ><slot>{{ switchLabel }}</slot></span>
    </label>
  </form-field>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    switchLabel: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      focussed: false,
    };
  },
  computed: {
    classes() {
      return {
        'toggle--focussed': this.focussed,
        'toggle--checked': this.value,
        'toggle--loading': this.loading,
        'toggle--disabled': this.disabled,
        'toggle--valid': this.valid,
        'toggle--invalid': this.invalid,
      };
    },
    hasLabel() {
      return !!this.switchLabel || !!this.$slots.default;
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.toggle {
  display: flex;
  align-items: center;
  justify-items: center;
  height: 48px;
  color: $color-white;
  font-size: 0;
  cursor: pointer;

  &--checked {
    .switch {
      background-color: $color-primary;
    }

    .knob {
      left: 24px;
    }
  }

  &--disabled,
  &--loading {
    cursor: auto;
    pointer-events: none;

    .switch {
      background-color: $color-gray50;
    }

    .knob {
      box-shadow: none;
    }

    .switch-label {
      color: $color-gray50;
    }
  }

  &--loading {
    .switch {
      color: $color-text;
    }

    .knob {
      opacity: 0;
    }
  }
}

.checkbox {
  display: none;
}

.switch {
  position: relative;
  display: flex;
  align-items: center;
  width: 52px;
  height: 30px;
  margin: 0;
  font-size: 12px;
  background-color: $color-gray70;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 200ms ease, color 200ms ease;
  user-select: none;
}

.icon {
  &--enabled {
    margin-left: 7px;
  }

  &--disabled {
    margin-right: 7px;
    margin-left: auto;
  }

  &--loading {
    margin-right: auto;
    margin-left: auto;
  }
}

.knob {
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 2;
  width: 26px;
  height: 26px;
  background-color: $color-white;
  border-radius: 100%;
  box-shadow: 0 2px 4px rgba($color-text-dark, 0.7);
  transition: all 200ms ease;
}

.switch-label {
  margin-left: 12px;
  color: $color-text;
  font-size: 14px;
  transition: color 200ms ease;
}
</style>
