export default [
  {
    path: '/results',
    name: 'results',
    component: () => import(/* webpackChunkName: "results" */ '@/views/Results.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];
