<template>
  <div
    class="base-widget-group"
  >
    <base-title :level="2">
      {{ title }}
    </base-title>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.base-widget-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
</style>
