<template>
  <router-link
    :to="to"
    class="main-menu-item"
    active-class="main-menu-item--active"
    exact-active-class="main-menu-item--exact-active"
  >
    <span
      v-if="icon"
      class="icon"
    >
      <base-icon
        :name="icon"
        :size="26"
      />
    </span>
    <span
      v-show="label"
      class="label"
    ><span class="label__text">{{ label }}</span></span>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: [String, Object],
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.main-menu-item {
  display: flex;
  align-items: center;
  margin-right: -15px;
  margin-left: -15px;
  padding: 15px;
  color: $color-text;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  border-radius: 8px;
  transition: color $page-transition-duration/2 ease;

  &--active {
    color: $color-primary;
    background-color: $color-white;
  }

  &:hover {
    color: $color-primary;
  }
}

.icon {
  flex-shrink: 0;
  text-align: center;
}

.label {
  position: relative;
  margin-left: 16px;
}

@media (max-width: 1200px) {
  .item {
    justify-content: center;
    margin-right: 0;
    margin-left: 0;

    &--active {
      background-color: transparent;
    }

    &:hover {
      .label {
        display: flex;
      }
    }
  }

  .label {
    position: absolute;
    left: 100%;
    display: none;
    align-items: center;
    height: 32px;
    margin-left: -8px;
    padding: 0 8px;
    font-size: 14px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: $overlay-shadow;

    &__text {
      z-index: 1;
    }

    &::before {
      position: absolute;
      top: 2.5px;
      left: -8px;
      display: block;
      width: 26px;
      height: 26px;
      background-color: $color-white;
      border-radius: 6px;
      transform: rotate(45deg);
      content: '';
    }
  }
}
</style>
