<template>
  <div>
    <section
      v-if="form"
      class="section"
    >
      <base-title
        :level="2"
        class="title"
      >
        {{ isEditing ? 'Edit Team' : 'Create Team' }}
      </base-title>

      <base-paragraph>
        Always make sure your team has at least {{ user.minimum_employees }} team members,
        or it will not show up in the results.
      </base-paragraph>

      <form
        method="POST"
        @submit.prevent="submitForm(true)"
        @keyup.enter="submitForm(true)"
      >
        <fieldset class="form__fieldset">
          <form-input
            v-model="form.name"
            name="name"
            label="Team Name"
            :loading="$apollo.queries.team.loading"
            :disabled="isFormDisabled"
            :invalid="form.errors.has('name')"
            :errors="form.errors.get('name')"
            full-width
            autofocus
            @input="form.errors.clear('name')"
          />
        </fieldset>

        <base-group
          class="form__actions"
          align="right"
          spacing="medium"
        >
          <base-button
            v-if="isEditing"
            type="danger"
            label="Delete"
            icon="trash-2-outline"
            :loading="!!isDeleting"
            :disabled="isDeleteDisabled"
            link
            @click="deleteTeam"
          />
          <base-button
            v-else
            type="primary"
            label="Create Another"
            icon="save-outline"
            :loading="!!isSubmitting"
            :disabled="isSubmitDisabled"
            link
            @click="submitForm(true)"
          />
          <base-button
            :loading="!!isSubmitting"
            :disabled="isSubmitDisabled"
            :label="isEditing ? 'Save' : 'Create'"
            icon="save-outline"
            @click="submitForm"
          />
        </base-group>
      </form>
    </section>
    <div v-else>
      Team unavailable
    </div>
  </div>
</template>

<script>
/* Import Queries */
import USER_QUERY from '@/graphql/queries/User.gql';
import TEAM_QUERY from '@/graphql/queries/Team.gql';
import TEAMS_QUERY from '@/graphql/queries/Teams.gql';
import EMPLOYEES_QUERY from '@/graphql/queries/Employees.gql';

/* Import Mutations */
import CREATE_TEAM_MUTATION from '@/graphql/mutations/CreateTeam.gql';
import UPDATE_TEAM_MUTATION from '@/graphql/mutations/UpdateTeam.gql';
import DELETE_TEAM_MUTATION from '@/graphql/mutations/DeleteTeam.gql';

/* Import Utils */
import EventBus from '@/utils/eventBus';

/* Import Classes and Helpers */
import Form from '@/utils/Form';
import { notifyFormSuccess, notifyFormError } from '@/utils/notifications';

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userId: this.$root.$data.userId,
      form: new Form({
        name: '',
      }),
      isSubmitting: 0,
      isDeleting: 0,
    };
  },
  computed: {
    isEditing() {
      return !!this.id;
    },
    isFormDisabled() {
      return !!this.isSubmitting || !!this.isDeleting;
    },
    isDeleteDisabled() {
      return !!this.isSubmitting || this.$apollo.queries.team.loading;
    },
    isSubmitDisabled() {
      return !!this.isDeleting
        || this.$apollo.queries.team.loading
        || this.form.isDefault
        || this.form.errors.any();
    },
  },
  apollo: {
    user: {
      query: USER_QUERY,
    },
    team: {
      query: TEAM_QUERY,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.isEditing;
      },
      update(data) {
        if (Object.keys(data).length > 0 && data.constructor === Object) {
          this.form = new Form({
            name: data.team.name,
          });
        }
      },
    },
  },
  methods: {
    async createTeam(createAnother) {
      this.isSubmitting += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: CREATE_TEAM_MUTATION,
          variables: { user_id: this.userId, ...this.form.data },
          refetchQueries: [
            {
              query: TEAMS_QUERY,
              variables: {
                user_id: this.userId,
              },
            },
          ],
          // Add temporary 'fake' result as soon as the request is made
          optimisticResponse: {
            __typename: 'Mutation',
            createTeam: {
              __typename: 'Team',
              id: '-1',
              name: this.form.name,
              employees_count: 0,
              added_employees_count: 0,
              invited_employees_count: 0,
              active_employees_count: 0,
              inactive_employees_count: 0,
              has_enough_employees: false,
            },
          },
          // Update the cache with the result when the request has finished
          update: (store, { data: { createTeam } }) => {
            const variables = { user_id: this.userId };
            // Read the data from our cache for this query.
            const data = store.readQuery({ query: TEAMS_QUERY, variables });
            // Add our team from the list
            data.teams.push(createTeam);
            // Write our data back to the cache.
            store.writeQuery({
              query: TEAMS_QUERY,
              variables: {
                user_id: this.userId,
              },
              data,
            });
          },
        })
        .then((data) => {
          // Result
          const team = data.data.createTeam;
          EventBus.$emit('team-created', team);
          notifyFormSuccess(`Team ${team.name} has been created.`);
          if (createAnother) {
            this.resetForm();
          } else {
            this.return();
          }
        })
        .catch((error) => {
          // Error
          notifyFormError();
          this.form.errors.record(error);
        })
        .finally(() => {
          this.isSubmitting -= 1;
        });
    },
    async updateTeam() {
      this.isSubmitting += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: UPDATE_TEAM_MUTATION,
          variables: {
            id: this.id,
            name: this.form.name,
          },
          refetchQueries: [
            {
              query: EMPLOYEES_QUERY,
              variables: {
                user_id: this.userId,
              },
            },
          ],
        })
        .then((data) => {
          // Result
          const team = data.data.updateTeam;
          // EventBus.$emit('team-updated', team);
          notifyFormSuccess(`Team ${team.name} has been renamed.`);
          this.return();
        })
        .catch((error) => {
          // Error
          notifyFormError();
          this.form.errors.record(error);
        })
        .finally(() => {
          this.isSubmitting -= 1;
        });
    },
    async deleteTeam() {
      this.isDeleting += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: DELETE_TEAM_MUTATION,
          variables: { id: this.id },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteTeam: {
              __typename: 'Team',
              id: this.id,
              name: this.form.name,
            },
          },
          // Update the cache with the result when the request has finished
          update: (store, { data: { deleteTeam } }) => {
            const variables = { user_id: this.userId };
            // Read the data from our cache for this query.
            const data = store.readQuery({ query: TEAMS_QUERY, variables });
            // Find our team in the list of teams
            data.teams = data.teams.filter((team) => team.id !== deleteTeam.id);
            // Write our data back to the cache.
            store.writeQuery({ query: TEAMS_QUERY, variables, data });
          },
        })
        .then((data) => {
          // Result
          const team = data.data.deleteTeam;
          EventBus.$emit('team-deleted', team);
          notifyFormSuccess(`${team.name} has been deleted`);
          this.return();
        })
        .catch(() => {
          // Error
          notifyFormError('The team could not be deleted');
        })
        .finally(() => {
          this.isDeleting -= 1;
        });
    },
    submitForm(creatAnother) {
      if (this.isEditing) {
        this.updateTeam();
      } else {
        this.createTeam(creatAnother);
      }
    },
    resetForm() {
      this.form.reset();
    },
    return() {
      this.$router.push({
        name: 'employees',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-right: 40px;
}
</style>
