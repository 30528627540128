<template>
  <section
    class="page"
    :class="{ 'page--dark-mode': darkMode }"
  >
    <div class="logo" />
    <main class="main">
      <div class="content">
        <slot />
      </div>
    </main>
    <base-button
      v-if="buttonLabel"
      class="button"
      :label="buttonLabel"
      :to="buttonTo"
      :type="darkMode ? 'ghost-inverted' : 'ghost'"
      size="small"
      @click="$emit('click-button')"
    />
  </section>
</template>

<script>
export default {
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: String,
      default: '',
    },
    buttonTo: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasAside() {
      return !!this.$slots.aside;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  min-height: 100vh;

  &--dark-mode {
    color: $color-white;
    background-color: $color-primary90;

    .logo {
      background-image: url('~@/assets/images/logo/light.svg');
    }
  }
}

.logo {
  position: absolute;
  top: 3vw;
  left: 3vw;
  width: 80px;
  height: 30px;
  background: url('~@/assets/images/logo/dark.svg') no-repeat center center / 100% 100%;
}

.button {
  position: absolute;
  top: 3vw;
  right: 3vw;
}

.main {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 0;
  min-height: 100%;
}

.content {
  flex-grow: 1;
  min-width: 0;
  max-width: 720px;
  padding: 3vw;
}
</style>
