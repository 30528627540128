<template>
  <router-link
    class="base-menu-item"
    :to="to"
    active-class="base-menu-item--active"
    exact-active-class="base-menu-item--exact-active"
  >
    <slot>
      <span class="label">{{ label }}</span>
      <base-status-light
        v-if="statusLight"
        class="status-light"
        :type="statusLight"
      />
      <base-badge
        v-if="badge || badge === 0"
        class="badge"
        type="white"
      >
        {{ badge }}
      </base-badge>
    </slot>
  </router-link>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    badge: {
      type: String,
      default: null,
    },
    statusLight: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-menu-item {
  display: flex;
  align-items: center;
  margin: 0 -8px;
  padding: 12px;
  color: $color-text;
  text-decoration: none;
  border-radius: 6px;
  cursor: pointer;

  &:hover,
  &--hovered {
    color: $color-primary;
    background-color: $color-white;

    .badge {
      box-shadow: 0 2px 8px rgba($color-text-dark, 0.08);
    }
  }

  &--active {
    color: $color-primary;
    font-weight: bold;
  }
}

.label {
  flex-grow: 1;
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.light {
  flex-shrink: 0;
  margin-right: 8px;
}
</style>
