<template>
  <aside
    class="main-menu"
  >
    <div class="header">
      <router-link
        :to="{ name: 'dashboard' }"
        class="logo"
        active-class="logo--active"
        exact-active-class="logo--exact-active"
      />
    </div>
    <nav
      class="body"
    >
      <main-menu-item
        v-for="item in items"
        :key="item.label"
        :label="item.label"
        :icon="item.icon"
        :to="item.to"
      />
    </nav>
    <div class="footer">
      <account-menu
        class="account"
        @logout="$emit('logout')"
      />
    </div>
  </aside>
</template>

<script>
/* Import Components */
import MainMenuItem from '@/components/layout/MainMenuItem.vue';
import AccountMenu from '@/components/layout/AccountMenu.vue';

export default {
  components: {
    MainMenuItem,
    AccountMenu,
  },
  data() {
    return {
      items: [
        {
          label: 'Dashboard', icon: 'grid-outline', to: { name: 'dashboard' },
        },
        {
          label: 'Teams', icon: 'people-outline', to: { name: 'employees' },
        },
        {
          label: 'Results', icon: 'pie-chart-outline', to: { name: 'results' },
        },
        {
          label: 'Insights', icon: 'bulb-outline', to: { name: 'insights' },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.main-menu {
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 20vw;
  padding: 3vw;
  // padding-top: 64px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.body {
  flex-grow: 1;
  box-sizing: border-box;
}

.footer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding-top: 16px;
  // border-top: solid 1px $color-gray30;
}

.logo {
  display: block;
  flex-shrink: 0;
  width: 80px;
  height: 30px;
  margin-right: auto;
  background: url('~@/assets/images/logo/dark.svg') no-repeat center center / 100% 100%;
}

.account {
  max-width: 100%;
}

@media (max-width: 1200px) {
  .main-menu {
    flex-shrink: 0;
    align-items: center;
    width: 80px;
    margin-right: 16px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .footer {
    align-items: center;
  }

  .logo {
    width: 40px;
    height: 40px;
    margin-right: auto;
    margin-left: auto;
    background: url('~@/assets/images/avatar/dark.svg') no-repeat center center / 100% 100%;
  }

  .account {
    align-self: stretch;
  }
}

</style>
