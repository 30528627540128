<template>
  <div
    v-click-outside="close"
    class="notifications-menu"
  >
    <div
      class="button"
      :class="{ 'button--active': open }"
      @click="open = !open"
    >
      <base-icon
        class="button-icon"
        name="bell-outline"
        size="24"
      />
      <base-status-light
        class="button-light"
        type="warning"
      />
    </div>
    <base-dropdown
      v-show="open"
      class="dropdown"
    >
      Hier komen dan dus straks de notifications
    </base-dropdown>
  </div>
</template>

<script>

export default {
  data() {
    return {
      open: false,
    };
  },
  methods: {
    close() {
      this.open = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications-menu {
  position: relative;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  overflow: hidden;
  background-color: $color-white;
  border-radius: 100%;
  box-shadow: 0 4px 16px rgba($color-text, 0.2);
  cursor: pointer;
  transition: background-color 200ms ease, color 200ms ease;

  &:hover:not(.button--active) {
    .button-light {
      border-color: $color-white;
    }
  }

  &--active {
    color: $color-white;
    background-color: $color-primary90;

    .button-light {
      border-color: $color-primary90;
    }
  }
}

.button-icon {
  cursor: pointer;
}

.button-light {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -15px;
  margin-right: -14px;
  border: solid 2px $color-white;
  transition: all 200ms ease;
}

.dropdown {
  position: absolute;
  right: 100%;
  bottom: 0;
  width: 200px;
  margin-right: 16px;
  padding: 16px;
}
</style>
