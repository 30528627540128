<template>
  <span
    class="base-status-light"
    :class="'base-status-light--' + type"
    :title="title"
  />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
      validator(value) {
        // The value must match one of these strings
        return (
          [
            'default',
            'off',
            'primary',
            'secondary',
            'success',
            'warning',
            'danger',
            'added',
            'invited',
            'active',
            'inactive',
          ].indexOf(value) !== -1
        );
      },
    },
  },
  computed: {
    title() {
      switch (this.type) {
        case 'added':
          return 'This team member has not been invited yet';
        case 'invited':
          return 'This team member has been invited';
        case 'active':
          return 'This team member is actively participating';
        case 'inactive':
          return 'This team member has not been actively participating';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.base-status-light {
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: middle;
  background-color: lightgray;
  border-radius: 19px;

  &--primary {
    background-color: $color-primary;
  }

  &--secondary {
    background-color: $color-secondary;
  }

  &--added {
    background-color: $color-gray50;
  }

  &--invited {
    background-color: $color-notice;
  }

  &--success,
  &--active {
    background-color: $color-success;
  }

  &--warning,
  &--inactive {
    background-color: $color-warning;
  }

  &--danger {
    background-color: $color-danger;
  }

  &--off {
    background-color: $color-white;
    box-shadow: inset 0 0 0 1px $color-gray50;
  }
}
</style>
