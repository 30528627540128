import Vue from 'vue';

import VueApollo from 'vue-apollo';
import Notifications from 'vue-notification';
import VueCroppie from 'vue-croppie';
import PortalVue from 'portal-vue';

Vue.use(VueApollo);
Vue.use(Notifications);
Vue.use(PortalVue);
Vue.use(VueCroppie);
