<template>
  <div
    class="base-modal"
    :class="{ 'base-modal--open': open }"
  >
    <transition-fade>
      <div
        v-if="open"
        class="backdrop"
        @click="close()"
      />
    </transition-fade>
    <transition-fade-zoom-in>
      <div
        v-if="open"
        class="dialog"
      >
        <slot />
        <div
          v-if="closable"
          class="close"
          @click="close"
        >
          <base-icon
            name="close-outline"
            size="20"
          />
        </div>
      </div>
    </transition-fade-zoom-in>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  destroyed() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onKeyDown(e) {
      if (e.key === 'Escape' && this.open) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.base-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow: auto;
  pointer-events: none;
  -webkit-overflow-scrolling: touch;

  &--open {
    pointer-events: auto;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color-gray90, 0.5);
}

.dialog {
  position: relative;
  width: 460px;
  max-width: 100%;
  margin: auto;
  padding: 48px;
  background-color: $color-white;
  border-radius: 16px;
  box-shadow: $overlay-shadow;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: $color-gray90;
  border: solid 1px $color-gray90;
  border-radius: 100%;
  cursor: pointer;
  transition: opacity 150ms ease, color 150ms ease;

  &:hover {
    color: $color-primary;
    border-color: $color-primary;
  }
}
</style>
