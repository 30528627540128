<template>
  <section
    class="base-page"
  >
    <main class="main">
      <div class="content">
        <slot />
      </div>
    </main>
    <aside
      class="aside"
      :class="{'aside--hidden': !hasAside}"
    >
      <template v-if="hasAside">
        <base-title
          v-if="title"
          :level="2"
        >
          {{ title }}
        </base-title>
        <slot name="aside" />
      </template>
    </aside>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasAside() {
      return !!this.$slots.aside;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-page {
  display: flex;
  min-height: 100%;
}

.main {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  box-sizing: border-box;
  min-width: 0;
}

.content {
  flex-grow: 1;
  min-width: 0;
  max-width: 1024px;
  margin-top: 2vw;
  // margin-top: 48px;
}

.aside {
  position: sticky;
  top: 0;
  flex-shrink: 0;
  align-self: flex-start;
  box-sizing: border-box;
  width: 100%;
  max-width: 20vw;
  height: 100vh;
  padding: 3vw;
  // padding-top: 64px;
  overflow-y: scroll;

  &--hidden {
    flex-grow: 10;
    width: auto;
    min-width: 3vw;
    padding: 0;
    visibility: hidden;
  }
}

</style>
