<template>
  <div
    class="base-notice"
    :class="'base-notice--' + type"
  >
    <base-icon
      v-if="icon"
      class="icon"
      :name="icon"
      :size="20"
    />
    <div class="content">
      <slot>{{ message }}</slot>
    </div>
    <base-button
      v-if="buttonLabel"
      class="button"
      :label="buttonLabel"
      :type="type === 'default' ? 'primary' : type"
      :icon="buttonIcon"
      :loading="buttonLoading"
      :to="buttonTo"
      size="tiny"
      @click="$emit('button-click')"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'primary',
      validator(value) {
        // The value must match one of these strings
        return (
          [
            'default',
            'primary',
            'warning',
            'danger',
            'success',
          ].indexOf(value) !== -1
        );
      },
    },
    message: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    buttonLabel: {
      type: String,
      default: null,
    },
    buttonIcon: {
      type: String,
      default: null,
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
    buttonTo: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-notice {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  color: $color-gray70;
  font-size: 16px;
  line-height: 22px;
  background-color: $color-gray10;
  border-radius: 2px;

  &--primary {
    color: $color-primary;
    background-color: lighten($color-primary, 42%);
  }

  &--danger {
    color: $color-danger;
    background-color: lighten($color-danger, 42%);
  }

  &--warning {
    color: $color-warning;
    background-color: lighten($color-warning, 45%);
  }

  &--success {
    color: $color-success;
    background-color: lighten($color-success, 46%);
  }
}

.icon {
  flex-shrink: 0;
  margin-left: 16px;
}

.content {
  flex-grow: 1;
  padding: 16px;
}

.button {
  flex-shrink: 0;
  margin-right: 16px;
}
</style>
