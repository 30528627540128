import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';

import Cookies from 'js-cookie';

export function createProvider() {
  // Cache implementation
  const cache = new InMemoryCache();
  // HTTP connection to the API
  const link = from([
    // Error handler
    onError((errorHandler) => {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; '
          + 'border-radius: 3px; font-weight: bold;',
        errorHandler,
      );
    }),
    // Replacement for 'new httpLink' that enables multipart file uploads
    createUploadLink({
      uri: process.env.VUE_APP_GRAPHQL_HTTP,
      // Send XSRF token with each request
      credentials: 'include',
      headers: { 'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') },
      // We use cookies so getAuth() does not need to get a token from the authorization header
      getAuth: () => undefined,
    }),
  ]);

  // Create the apollo client
  const apolloClient = new ApolloClient({
    link,
    cache,
    connectToDevTools: true,
  });

  // Create the apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; '
          + 'border-radius: 3px; font-weight: bold;',
        error.message,
      );
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient) {
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  try {
    await apolloClient.clearStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}
