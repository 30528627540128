class FormErrors {
  constructor(response = null) {
    this.errors = {};
    this.record(response);
  }

  has(field) {
    return Object.prototype.hasOwnProperty.call(this.errors, field);
  }

  any() {
    return Object.keys(this.errors).length > 0;
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field];
    }
    return null;
  }

  record(response) {
    if (!response) return;
    const { graphQLErrors } = response;
    if (graphQLErrors[0].extensions.category === 'validation') {
      this.errors = graphQLErrors[0].extensions.validation;
    }
  }

  clear(field) {
    if (field) {
      delete this.errors[field];
    } else {
      this.errors = {};
    }
  }
}

export default FormErrors;
