<template>
  <div
    class="base-card"
  >
    <slot />
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.base-card {
  margin-bottom: 48px;
  padding: 3vw;
  background-color: $color-white;
  border-radius: 32px;
  box-shadow: 0 16px 32px rgba($color-primary90, 0.075);
}
</style>
