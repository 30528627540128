<template>
  <div>
    <section
      v-if="form"
      class="section"
    >
      <base-title :level="1">
        {{ isEditing ? 'Edit Team Member' : 'Add Team Member' }}
      </base-title>

      <base-paragraph>
        Team members will not be invited automatically,
        only you decide when the invitations will be sent out.
      </base-paragraph>

      <form
        method="POST"
        @submit.prevent="submitForm(true)"
        @keyup.enter="submitForm(true)"
      >
        <fieldset class="form__fieldset form__fieldset--no-margin">
          <form-group
            align="justify"
          >
            <form-input
              v-model="form.first_name"
              name="first_name"
              label="First Name"
              :loading="$apollo.queries.employee.loading"
              :disabled="isFormDisabled"
              :invalid="form.errors.has('first_name')"
              :errors="form.errors.get('first_name')"
              full-width
              autofocus
              @input="form.errors.clear('first_name')"
            />

            <form-input
              v-model="form.last_name"
              name="last_name"
              label="Last Name"
              :loading="$apollo.queries.employee.loading"
              :disabled="isFormDisabled"
              :invalid="form.errors.has('last_name')"
              :errors="form.errors.get('last_name')"
              full-width
              @input="form.errors.clear('last_name')"
            />
          </form-group>

          <form-input
            v-model="form.email"
            name="email"
            label="E-Mail Address"
            :loading="$apollo.queries.employee.loading"
            :disabled="isFormDisabled"
            :invalid="form.errors.has('email')"
            :errors="form.errors.get('email')"
            full-width
            @input="form.errors.clear('email')"
          />
        </fieldset>

        <div class="divider" />

        <fieldset
          v-show="teams.length"
          class="form__fieldset"
        >
          <form-select
            v-model="form.team_id"
            placeholder="None"
            name="team_id"
            label="Team"
            :options="teams"
            option-value="id"
            option-label="name"
            :loading="$apollo.queries.teams.loading || $apollo.queries.employee.loading"
            :disabled="isFormDisabled"
            :invalid="form.errors.has('team_id')"
            :errors="form.errors.get('team_id')"
            @input="form.errors.clear('team_id')"
          />
        </fieldset>

        <base-group
          class="form__actions"
          align="right"
          spacing="medium"
        >
          <base-button
            v-if="isEditing"
            type="danger"
            label="Delete"
            icon="trash-2-outline"
            :loading="!!isDeleting"
            :disabled="isDeleteDisabled"
            link
            @click="deleteEmployee"
          />
          <base-button
            v-else
            type="primary"
            label="Create Another"
            icon="save-outline"
            :loading="!!isSubmitting"
            :disabled="isSubmitDisabled"
            link
            @click="submitForm(true)"
          />
          <base-button
            :loading="!!isSubmitting"
            :disabled="isSubmitDisabled"
            :label="isEditing ? 'Save' : 'Create'"
            icon="save-outline"
            @click="submitForm"
          />
        </base-group>
      </form>
    </section>
    <div v-else>
      Employee unavailable
    </div>
  </div>
</template>

<script>
/* Import Queries */
import EMPLOYEE_QUERY from '@/graphql/queries/Employee.gql';
import TEAMS_QUERY from '@/graphql/queries/Teams.gql';
import USER_QUERY from '@/graphql/queries/User.gql';
import EMPLOYEES_QUERY from '@/graphql/queries/Employees.gql';
import EMPLOYEE_STATUSES_QUERY from '@/graphql/queries/EmployeeStatuses.gql';

/* Import Mutations */
import CREATE_EMPLOYEE_MUTATION from '@/graphql/mutations/CreateEmployee.gql';
import UPDATE_EMPLOYEE_MUTATION from '@/graphql/mutations/UpdateEmployee.gql';
import DELETE_EMPLOYEE_MUTATION from '@/graphql/mutations/DeleteEmployee.gql';

/* Import Classes and Helpers */
import Form from '@/utils/Form';
import { notifyFormSuccess, notifyFormError } from '@/utils/notifications';

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    teamId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userId: this.$root.$data.userId,
      form: new Form({
        first_name: '',
        last_name: '',
        email: '',
        team_id: this.teamId,
      }),
      isSubmitting: 0,
      isDeleting: 0,
    };
  },
  computed: {
    isEditing() {
      return !!this.id;
    },
    isFormDisabled() {
      return !!(this.isSubmitting || this.isDeleting);
    },
    isDeleteDisabled() {
      return !!(this.isSubmitting || this.$apollo.queries.employee.loading);
    },
    isSubmitDisabled() {
      return !!(this.isDeleting
        || this.$apollo.queries.employee.loading
        || this.form.isDefault
        || this.form.errors.any()
      );
    },
  },
  apollo: {
    employee: {
      query: EMPLOYEE_QUERY,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.isEditing;
      },
      update(data) {
        if (Object.keys(data).length > 0 && data.constructor === Object) {
          this.form.set({
            email: data.employee.email,
            first_name: data.employee.first_name,
            last_name: data.employee.last_name,
            team_id: data.employee.team ? data.employee.team.id : '',
          });
        } else {
          this.form = null;
        }
      },
    },
    teams: {
      query: TEAMS_QUERY,
      variables() {
        return {
          user_id: this.userId,
        };
      },
    },
  },
  methods: {
    async createEmployee(createAnother) {
      this.isSubmitting += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: CREATE_EMPLOYEE_MUTATION,
          variables: { user_id: this.userId, ...this.form.data },
          refetchQueries: [
            {
              query: USER_QUERY,
            },
            {
              query: EMPLOYEES_QUERY,
              variables: {
                user_id: this.userId,
              },
            },
            {
              query: EMPLOYEE_STATUSES_QUERY,
              variables: {
                user_id: this.userId,
              },
            },
            {
              query: EMPLOYEE_STATUSES_QUERY,
              variables: {
                user_id: this.userId,
                team_id: this.form.team_id ? this.form.team_id : undefined,
              },
            },
          ],
          // Add temporary 'fake' result as soon as the request is made
          optimisticResponse: {
            __typename: 'Mutation',
            createEmployee: {
              __typename: 'Employee',
              id: '-1',
              first_name: this.form.first_name,
              last_name: this.form.last_name,
              email: this.form.email,
              team: {
                __typename: 'Team',
                id: this.form.team_id,
                name: '',
                employees_count: 0,
                added_employees_count: 0,
                invited_employees_count: 0,
                active_employees_count: 0,
                inactive_employees_count: 0,
                has_enough_employees: false,
              },
              employee_status: {
                __typename: 'EmployeeStatus',
                id: '1',
                name: 'Added',
              },
            },
          },
          // Update the cache with the result when the request has finished
          update: (store, { data: { createEmployee } }) => {
            const variables = { user_id: this.userId };
            // Read the data from our cache for this query.
            const data = store.readQuery({ query: EMPLOYEES_QUERY, variables });
            // Add our team from the list
            data.employees.push(createEmployee);
            // Write our data back to the cache.
            store.writeQuery({ query: EMPLOYEES_QUERY, variables, data });
          },
        })
        .then((data) => {
          // Result
          const employee = data.data.createEmployee;
          notifyFormSuccess(`Team member ${employee.first_name} ${employee.last_name} has been added.`);
          if (createAnother) {
            this.resetForm();
          } else {
            this.return();
          }
        })
        .catch((error) => {
          // Error
          notifyFormError();
          this.form.errors.record(error);
        })
        .finally(() => {
          this.isSubmitting -= 1;
        });
    },
    async updateEmployee() {
      this.isSubmitting += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: UPDATE_EMPLOYEE_MUTATION,
          variables: { id: this.id, ...this.form.data },
          refetchQueries: [
            {
              query: USER_QUERY,
            },
            {
              query: TEAMS_QUERY,
              variables: {
                user_id: this.userId,
              },
            },
          ],
        })
        .then(() => {
          // Result
          notifyFormSuccess('The employee details have been saved.');
          this.return();
        })
        .catch((error) => {
          // Error
          notifyFormError();
          this.form.errors.record(error);
        })
        .finally(() => {
          this.isSubmitting -= 1;
        });
    },
    async deleteEmployee() {
      this.isDeleting += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: DELETE_EMPLOYEE_MUTATION,
          variables: { id: this.id },
          refetchQueries: [
            {
              query: USER_QUERY,
            },
            {
              query: TEAMS_QUERY,
              variables: {
                user_id: this.userId,
              },
            },
            {
              query: EMPLOYEE_STATUSES_QUERY,
              variables: {
                user_id: this.userId,
              },
            },
            {
              query: EMPLOYEE_STATUSES_QUERY,
              variables: {
                user_id: this.userId,
                team_id: this.form.team_id ? this.form.team_id : undefined,
              },
            },
          ],
          optimisticResponse: {
            __typename: 'Mutation',
            deleteEmployee: {
              __typename: 'Employee',
              id: this.id,
              first_name: this.form.first_name,
              last_name: this.form.last_name,
              email: this.form.email,
              team: {
                __typename: 'Team',
                id: this.form.team_id,
                name: '',
              },
            },
          },
          // Update the cache with the result when the request has finished
          update: (store, { data: { deleteEmployee } }) => {
            const variables = { user_id: this.userId };
            // Read the data from our cache for this query.
            const data = store.readQuery({ query: EMPLOYEES_QUERY, variables });
            // Find our team in the list of teams
            data.employees = data.employees.filter((employee) => employee.id !== deleteEmployee.id);
            // Write our data back to the cache.
            store.writeQuery({ query: EMPLOYEES_QUERY, variables, data });
          },
        })
        .then((data) => {
          // Result
          const employee = data.data.deleteEmployee;
          // EventBus.$emit('employee-deleted');
          notifyFormSuccess(`${employee.first_name} ${employee.last_name} has been deleted`);
          this.return();
        })
        .catch(() => {
          // Error
          notifyFormError('The employee could not be deleted');
        })
        .finally(() => {
          this.isDeleting -= 1;
        });
    },
    submitForm(createAnother) {
      if (this.isEditing) {
        this.updateEmployee();
      } else {
        this.createEmployee(createAnother);
      }
    },
    resetForm() {
      this.form.reset();
    },
    cancelForm() {
      this.$router.push({
        name: 'employees',
      });
    },
    return() {
      this.$router.push({
        name: 'employees',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-right: 40px;
}
</style>
