import EmployeeForm from '@/views/EmployeeForm.vue';
import TeamForm from '@/views/TeamForm.vue';

export default [
  {
    path: '/teams',
    name: 'employees',
    component: () => import(/* webpackChunkName: "employees" */ '@/views/Employees.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/teams/edit/:id',
        name: 'employees.teams.edit',
        components: {
          modal: TeamForm,
        },
        meta: {
          requiresAuth: true,
          showModal: true,
        },
        props: { modal: true },
      }, {
        path: '/teams/create',
        name: 'employees.teams.create',
        components: {
          modal: TeamForm,
        },
        meta: {
          requiresAuth: true,
          showModal: true,

        },
        props: { modal: true },
      },
      {
        path: '/teams/members/create',
        name: 'employees.create',
        components: {
          modal: EmployeeForm,
        },
        meta: {
          requiresAuth: true,
          showModal: true,
        },
        props: { modal: true },
      },
      {
        path: '/teams/members/edit/:id',
        name: 'employees.edit',
        components: {
          modal: EmployeeForm,
        },
        meta: {
          requiresAuth: true,
          showModal: true,
        },
        props: { modal: true },
      },
    ],
  },
];
