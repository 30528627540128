<template>
  <li
    class="base-list-item"
    :class="classes"
  >
    <span
      class="link"
      :class="{ 'link--hovered': hovered}"
      @click.prevent="$emit('click')"
      @dragover.prevent="$emit('dragover', $event)"
      @dragleave.prevent="$emit('dragleave', $event)"
      @drop.stop="$emit('drop', $event)"
    >
      <slot>
        <base-status-light
          v-if="status"
          class="status"
          :type="status"
        />
        <span class="label">{{ label }}</span>
        <base-icon
          v-if="warning"
          class="warning"
          name="alert-triangle"
          :size="15"
        />
        <base-badge
          v-if="badge !== null"
          class="badge"
        >{{ badge }}</base-badge>
      </slot>
    </span>
  </li>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
    badge: {
      type: [String, Number],
      default: null,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    hovered: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        { 'base-list-item--active': this.active },
        { 'base-list-item--disabled': this.disabled },
        { 'base-list-item--compact': this.compact },
        { 'base-list-item--light': this.light },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.base-list-item {
  &--disabled {
    .link {
      color: $color-gray50;
      cursor: auto;
      pointer-events: none;
    }
  }

  &--active {
    .link {
      color: $color-primary;
      font-weight: bold;
      cursor: pointer;
      pointer-events: auto;
    }
  }

  &--compact {
    .link {
      padding: 8px;
    }
  }

  &--light {
    .link {
      &:hover,
      &--hovered {
        background-color: $color-gray10;

        .badge {
          background-color: $color-white;
        }
      }
    }

    .badge {
      background-color: $color-gray10;
    }
  }
}

.link {
  display: flex;
  align-items: center;
  padding: 12px;
  color: $color-text;
  font-size: 14px;
  text-decoration: none;
  border-radius: 6px;
  cursor: pointer;

  &:hover,
  &--hovered {
    color: $color-primary;
    background-color: $color-white;

    .badge {
      background-color: $color-gray10;
    }
  }
}

.status {
  flex-shrink: 0;
  box-sizing: content-box;
  margin-right: 15px;
  border: solid 1px #fff;
}

.label {
  flex-grow: 1;
  margin-right: auto;
  overflow: hidden;
  line-height: 1.5em;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.warning {
  flex-shrink: 0;
  margin-left: 8px;
  color: $color-warning;
}

.badge {
  flex-shrink: 0;
  margin-left: 8px;
  background-color: $color-white;
}

</style>
