<template>
  <component
    :is="element"
    class="base-button"
    :class="classes"
    :href="href"
    :to="to"
    @click="$emit('click')"
  >
    <span class="content">
      <slot>
        <base-icon
          v-if="icon && iconPosition === 'left'"
          class="icon"
          :name="icon"
          :size="iconSize"
        />
        <span
          v-if="label"
          class="label"
        >{{ label }}</span>
        <base-icon
          v-if="icon && iconPosition === 'right'"
          class="button-icon"
          :name="icon"
          :size="iconSize"
        />
      </slot>
    </span>
    <base-icon
      v-if="loading"
      class="loader"
      name="loader-outline"
      animation="spin"
      :size="iconSize"
    />
  </component>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator(value) {
        // The value must match one of these strings
        return ['left', 'right'].indexOf(value) !== -1;
      },
    },
    type: {
      type: String,
      default: 'primary',
      validator(value) {
        // The value must match one of these strings
        return ['primary', 'secondary', 'danger', 'warning', 'ghost', 'ghost-inverted'].indexOf(value) !== -1;
      },
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        // The value must match one of these strings
        return ['large', 'medium', 'small', 'tiny'].indexOf(value) !== -1;
      },
    },
    link: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
  },
  computed: {
    element() {
      if (this.to) {
        return 'router-link';
      } if (this.href) {
        return 'a';
      }
      return 'span';
    },
    classes() {
      return [
        `base-button--${this.type}`,
        `base-button--${this.size}`,
        { 'base-button--link': this.link },
        { 'base-button--full-width': this.fullWidth },
        { 'base-button--disabled': this.disabled },
        { 'base-button--loading': this.loading },
        { 'base-button--icon': this.iconOnly },
      ];
    },
    iconSize() {
      switch (this.size) {
        case 'large':
          return 24;
        case 'medium':
          return 20;
        case 'small':
          return 16;
        case 'tiny':
          return 12;
        default:
          return null;
      }
    },
    iconOnly() {
      return this.icon && !this.label;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-button {
  $root: &;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  color: $color-text;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: transform 200ms ease, background-color 200ms ease, color 200ms ease;

  &--full-width {
    width: 100%;
  }

  &--primary {
    color: $color-white;
    background-color: $color-primary;

    &:hover {
      background-color: lighten($color-primary, 5);
    }
  }

  &--secondary {
    color: $color-text;
    background-color: $color-secondary;

    &:hover {
      background-color: lighten($color-secondary, 10);
    }
  }

  &--danger {
    color: $color-white;
    background-color: $color-danger;

    &:hover {
      background-color: lighten($color-danger, 5);
    }
  }

  &--warning {
    color: $color-white;
    background-color: $color-warning;

    &:hover {
      background-color: lighten($color-warning, 10);
    }
  }

  &--success {
    color: $color-white;
    background-color: $color-success;

    &:hover {
      background-color: lighten($color-success, 5);
    }
  }

  &--ghost {
    background: transparent;
    box-shadow: inset 0 0 0 2px $color-text;

    &:hover {
      color: $color-primary;
      box-shadow: inset 0 0 0 2px $color-primary;
    }

    &#{$root}--disabled {
      background-color: transparent;
      box-shadow: inset 0 0 0 2px $color-gray70;
    }
  }

  &--ghost-inverted {
    color: $color-white;
    background: transparent;
    box-shadow: inset 0 0 0 2px $color-white;

    // &:hover {
    //   box-shadow: inset 0 0 0 2px $color-gray50;
    //   color: $color-gray50;
    // }

    &#{$root}--disabled {
      background-color: transparent;
      box-shadow: inset 0 0 0 2px $color-gray30;
    }
  }

  &--large {
    height: 48px;
    padding: 0 20px;
    font-size: 18px;
    border-radius: 4px 10px;

    &#{$root}--icon {
      padding: 0 12px;
    }
  }

  &--medium {
    height: 40px;
    padding: 0 16px;
    font-size: 15px;
    border-radius: 2px 8px;

    &#{$root}--icon {
      padding: 0 10px;
    }
  }

  &--small {
    height: 32px;
    padding: 0 12px;
    font-size: 13px;
    border-radius: 1px 6px;

    &#{$root}--icon {
      padding: 0 8px;
    }
  }

  &--tiny {
    height: 24px;
    padding: 0 8px;
    font-size: 11px;
    border-radius: 1px 6px;

    &#{$root}--icon {
      padding: 0 6px;
    }
  }

  &--disabled {
    color: $color-gray70;
    background-color: $color-gray30;
    cursor: default;
    pointer-events: none;
  }

  &--loading {
    cursor: default;
    pointer-events: none;

    .content {
      color: transparent;
    }
  }

  &--link {
    height: auto;
    padding: 0;
    background: transparent;
    box-shadow: none;

    &:hover {
      text-decoration: underline;
      background: transparent;
      box-shadow: none;
    }

    &#{$root}--icon {
      padding: 0;
    }

    &#{$root}--primary {
      color: $color-primary;
    }

    &#{$root}--secondary {
      color: $color-secondary;
    }

    &#{$root}--danger {
      color: $color-danger;
    }

    &#{$root}--warning {
      color: $color-warning;
    }

    &#{$root}--success {
      color: $color-success;
    }

    &#{$root}--disabled {
      color: $color-gray70;
      background-color: transparent;
    }

    &#{$root}--loading {
      .content {
        color: transparent;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  transition: color 100ms ease;
}

.icon + .label,
.label + .icon {
  margin-left: 0.3em;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
