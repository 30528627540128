export default [
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import(/* webpackChunkName: "onboarding" */ '@/views/Onboarding.vue'),
    meta: {
      requiresAuth: true,
      hideMainMenu: true,
    },
  },
];
