import Vue from 'vue';

import App from '@/App.vue';

import router from '@/router';
import { createProvider } from '@/utils/apollo';
import '@/boot';

import '@/assets/sass/app.scss';

Vue.config.productionTip = false;

// Get XSRF token before instantiating Vue
fetch(process.env.VUE_APP_XSRF_URL, {
  credentials: 'include',
}).then(() => {
  // Create Vue instance
  window.vue = new Vue({
    router,
    apolloProvider: createProvider(),
    render: (h) => h(App),
  }).$mount('#app');
}).catch(() => {
  // eslint-disable-next-line no-console
  console.error('XSRF token could not be retrieved');
});
