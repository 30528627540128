<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 100ms ease;
}

.fade-enter,
.fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
