<template>
  <div
    class="base-level"
    :class="classes"
  >
    <div class="item item--left">
      <slot name="left" />
    </div>
    <div class="item item--center">
      <slot name="center" />
    </div>
    <div class="item item--right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    verticalAlign: {
      type: String,
      default: 'middle',
      validator(value) {
        // The value must match one of these strings
        return ['top', 'middle', 'bottom'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    classes() {
      return [
        `base-level--${this.verticalAlign}`,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.base-level {
  display: flex;

  &--top {
    align-items: top;
  }

  &--middle {
    align-items: center;
  }

  &--bottom {
    align-items: bottom;
  }
}

.item {
  display: flex;

  &--center {
    flex-grow: 1;
  }
}
</style>
