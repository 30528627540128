<template>
  <div
    class="account-menu-item"
    @click="$emit('click')"
  >
    <component
      :is="element"
      class="link"
      :class="type ? 'link--' + type : ''"
      :href="href"
      :to="to"
    >
      <slot>
        <base-icon
          v-if="icon"
          :name="icon"
          class="icon"
        />
        <span class="label">{{ label }}</span>
      </slot>
    </component>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
      validator(value) {
        // The value must match one of these strings
        return ['danger'].indexOf(value) !== -1;
      },
    },
    to: {
      type: [String, Object],
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
  },
  computed: {
    element() {
      if (this.to) {
        return 'router-link';
      } if (this.href) {
        return 'a';
      }
      return 'span';
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  display: flex;
  align-items: center;
  min-width: 128px;
  padding: 8px;
  color: $color-text;
  font-size: 14px;
  text-decoration: none;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    color: $color-primary;
    background-color: $color-gray10;
  }

  &--danger:hover {
    color: $color-danger;
  }
}

.icon {
  margin-right: 8px;
}
</style>
