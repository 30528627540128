<template>
  <div
    v-click-outside="close"
    class="account-menu"
    :class="{ 'account-menu--active': open }"
    @click="open = !open"
  >
    <div
      class="avatar"
    >
      <base-avatar
        class="avatar"
        :url="user ? user.avatar : ''"
        :name="user ? user.company_name : ''"
        size="100%"
      />
    </div>

    <div class="label">
      <div class="label-name">
        <base-skeleton-box v-if="$apollo.queries.user.loading" />
        <template v-else>
          {{ user ? user.company_name : '' }}
        </template>
      </div>
      <div class="label-email">
        <base-skeleton-box v-if="$apollo.queries.user.loading" />
        <template v-else>
          {{ user ? user.email : '' }}
        </template>
      </div>
    </div>

    <base-icon
      class="arrow"
      :name="open ? 'chevron-up-outline' : 'chevron-down-outline'"
    />

    <base-dropdown
      v-show="open"
      class="dropdown"
    >
      <account-menu-item
        label="Settings"
        icon="settings-2-outline"
        :to="{ name: 'settings', hash: '#account' }"
      />
      <account-menu-item
        label="Log Out"
        icon="log-out-outline"
        type="danger"
        @click="$emit('logout');"
      />
    </base-dropdown>
  </div>
</template>

<script>
/* Import Queries */
import USER_QUERY from '@/graphql/queries/User.gql';

/* Import Components */
import AccountMenuItem from '@/components/layout/AccountMenuItem.vue';

export default {
  components: {
    AccountMenuItem,
  },
  data() {
    return {
      open: false,
    };
  },
  apollo: {
    user: {
      query: USER_QUERY,
      skip() {
        return !this.$root.$data.userId;
      },
    },
  },
  methods: {
    close() {
      this.open = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.account-menu {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: $color-primary;
  }
}

.avatar {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  overflow: hidden;
  background-color: $color-gray30;
  border-radius: 100%;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover:not(.button--active) {
    background-color: $color-gray30;

    .button__light {
      border-color: $color-gray30;
    }
  }

  &--active {
    color: $color-white;
    background-color: $color-primary90;

    .button__light {
      border-color: $color-primary90;
    }
  }
}

.label {
  flex-grow: 1;
  min-width: 0;
  margin-left: 16px;
  white-space: nowrap;
}

.label-name {
  margin-bottom: 4px;
  overflow: hidden;
  font-weight: bold;
  font-size: 14px;
  text-overflow: ellipsis;
}

.label-email {
  overflow: hidden;
  font-size: 12px;
  text-overflow: ellipsis;
}

.dropdown {
  position: absolute;
  right: -8px;
  bottom: 100%;
  z-index: 10;
  margin-bottom: 12px;
  padding: 8px;
}

.arrow {
  margin-left: auto;
}

@media (max-width: 1200px) {
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: auto;
    margin-left: auto;
  }

  .label,
  .arrow {
    display: none !important;
  }

  .dropdown {
    right: auto;
    left: -8px;
  }
}
</style>
