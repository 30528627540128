<template>
  <transition
    name="fade-zoom"
    mode="out-in"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.fade-zoom-enter-active,
.fade-zoom-leave-active {
  transition: all 300ms ease;
}

.fade-zoom-enter,
.fade-zoom-leave-to {
  transform: scale(1.1);
  opacity: 0;
}
</style>
