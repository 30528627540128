import Vue from 'vue';
import VueRouter from 'vue-router';

import Error404 from '@/views/Error404.vue';
import dashboard from './dashboard';
import employees from './employees';
import examples from './examples';
import insights from './insights';
import login from './login';
import onboarding from './onboarding';
import results from './results';
import settings from './settings';
import setup from './setup';

Vue.use(VueRouter);

// Define routes
const routes = [
  ...dashboard,
  ...employees,
  ...examples,
  ...insights,
  ...login,
  ...onboarding,
  ...results,
  ...settings,
  ...setup,
  { path: '*', component: Error404 },
];

// Create new router instance
const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash && !to.params.instant) {
      const offset = 48;
      const element = document.querySelector(to.hash);
      const top = element ? element.offsetTop - offset : 0;
      return window.scrollTo({ top, behavior: 'smooth' });
    }
    return null;
  },
});

router.beforeEach((to, from, next) => {
  // Get login state
  const isLoggedIn = localStorage.getItem('user-id');
  // Check if this route requires authentication and the user is logged In
  if (to.matched.some((record) => record.meta.requiresAuth) && !isLoggedIn) {
    // Authorization is required for this route but the user is not logged in.
    // Redirect to login page.
    next({
      path: '/login',
      query: { redirect: to.name },
    });
  }
  // Simply continue to the next route
  next();
});

export default router;
