<template>
  <div
    class="base-avatar"
    :class="{ 'base-avatar--empty': !url }"
    :style="{ backgroundImage: `url('${url}')`, width: size, height: size }"
  >
    <div v-if="!url && acronym">
      {{ acronym }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '32px',
    },
  },
  computed: {
    acronym() {
      // Get the first letter of each word
      return this.name ? this.name.match(/\b(\w)/g).join('') : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  font-family: $font-family-title;
  background-size: cover;
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px rgba($color-text, 0.1);
  transition: filter 150ms ease;
  user-select: none;

  &--empty {
    box-shadow: none;
  }
}
</style>
