<template>
  <div
    id="app"
    class="app"
  >
    <transition-fade-zoom
      v-if="transitions"
      :direction="hideMainMenu ? 'out' : 'in'"
    >
      <div
        v-if="hideMainMenu"
        key="1"
        class="app__fullscreen"
      >
        <transition-fade>
          <router-view />
        </transition-fade>
      </div>

      <div
        v-else
        key="2"
        class="app__layout"
      >
        <main-menu
          class="app__menu"
          @logout="logout"
        />

        <div
          class="app__content"
        >
          <transition-fade>
            <router-view class="app__view" />
          </transition-fade>
        </div>
      </div>
    </transition-fade-zoom>

    <portal-target
      name="modal"
      slim
    />

    <notifications-menu
      v-if="!hideMainMenu"
      class="app__notifications-menu"
    />

    <notifications
      group="main"
      position="bottom right"
      width="100%"
      class="notifications"
      classes="notifications__item"
    />
  </div>
</template>

<script>
/* Import Queries */
import USER_QUERY from '@/graphql/queries/User.gql';

/* Import Mutations */
import LOGOUT_MUTATION from '@/graphql/mutations/Logout.gql';

/* Import Components */
import MainMenu from '@/components/layout/MainMenu.vue';
import NotificationsMenu from '@/components/layout/NotificationsMenu.vue';

/* Import Classes and Helpers */
import { onLogout } from '@/utils/apollo';

export default {
  components: {
    MainMenu,
    NotificationsMenu,
  },
  data() {
    return {
      hideMainMenu: true,
      transitions: false,
    };
  },
  watch: {
    $route: {
      handler() {
        this.transitions = true;
      },
    },
    '$route.meta.hideMainMenu': {
      handler(hideMainMenu) {
        this.hideMainMenu = !!hideMainMenu;
      },
      immediate: true,
    },
  },
  created() {
    // Get user ID from local storage
    this.$root.$data.userId = localStorage.getItem('user-id');
  },
  mounted() {
    this.mounted = true;
  },
  apollo: {
    user: {
      query: USER_QUERY,
      skip() {
        return !this.$route.meta.requiresAuth;
      },
      result(data) {
        const routeName = this.$router.currentRoute.name;
        const { user } = data.data;
        // Check if a user was returned
        if (!user) {
          // No user, redirect to login page
          this.$router.push({
            name: 'login',
          });
          return;
        }
        // Check if we should show onboarding
        const isOnboarding = routeName === 'onboarding';
        const onboardingCompleted = data.data.user.onboarding_completed;
        // Check if onboarding has not yet been completed, and we are not currently onboarding
        if (!onboardingCompleted && !isOnboarding) {
          // Move to onboarding route
          this.$router.push({
            name: 'onboarding',
          });
        }
      },
    },
  },
  methods: {
    async logout() {
      this.$apollo
        .mutate({ mutation: LOGOUT_MUTATION })
        .finally(async () => {
          // Clear user id from local storage
          localStorage.removeItem('user-id');
          // Run apollo logout actions
          onLogout(this.$apollo.provider.defaultClient);
          // Redirect to login route
          this.$router.push({
            name: 'login',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.app {
  &__layout {
    display: flex;
    min-height: 100vh;
  }

  &__menu {
    position: sticky;
    top: 0;
    z-index: 1;
    max-height: 100vh;
    // overflow-y: auto;
  }

  &__content {
    z-index: 0;
    flex-grow: 1;
    min-width: 0;
  }

  &__notifications-menu {
    position: fixed;
    right: 32px;
    bottom: 32px;
  }
}

</style>
