export default [
  {
    path: '/examples',
    name: 'examples',
    component: () => import(/* webpackChunkName: "examples" */ '@/views/Examples.vue'),
    meta: {
      requiresAuth: false,
    },
  },
];
