/**
 *
 * @param {String} title
 * @param {String} text
 */
function notifyFormSuccess(title = 'Team successfully created') {
  window.vue.$notify({
    group: 'main',
    type: 'success',
    title,
  });
}

/**
 *
 * @param {String} title
 * @param {String} text
 */
function notifyFormError(title = 'Please correct the form.') {
  window.vue.$notify({
    group: 'main',
    type: 'error',
    title,
  });
}

export { notifyFormSuccess, notifyFormError };
